import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import TopBar from '../components/service/TopBar';

import TopCard from '../components/service/TopCard';


function AirtimeToCashReadMe() {

       
    useEffect(() => {
      scrollToTop();
      // fetchPlans();
      return () => axios.CancelToken.source();
    },[]);
    
    
    return (
        <div>
    
    <TopBar icon="fas fa-phone"/>  
    
        <div className={"page-content " + Styles.page_content}>
    
    
      {/* Top Card */}
      <TopCard wallet={"null"}/>      
    
        <div className="card card-clear" style={{ height: "170px" }}></div>
    
      
    <div className={"page-content pb-4 " + Styles.pt_10}>
    
    <ToastContainer/>
    
    <div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>
    
    <div className={"content mb-0 " + Styles.pt_13}>


    <span> 



<h2> Introduction </h2>
<div className='mb-2'>
Airtime to Cash is a service designed to help people turn the airtime in their phone numbers or lines to cash that can either be paid to their bank accounts or be credited to their gracehubtels accounts to enable them to buy and sell services on our platform.
</div>

<div className='mb-2'>
Airtime to Cash simply means that you are transferring your airtime balance to us(our phone number(s) for you to be paid cash. In order words, if you don't transfer your airtime to our designated phone number, don't expect to be paid.
</div> 

<div className='mb-2'>
<b>Please note that Airtime to cash conversion is usually carried out at a discounted rate. This means that there is a service fee for the conversion and do not expect to get 100% of whatever amount of airtime you send us. You may get between 85-75% of the value of your airtime depending on the network involved. Do well to understand our rates before you proceed with the service.</b>
</div>

<div className='mb-2'>
<h3> Terms and Conditions(Read All) </h3>
</div>

<div className='mb-2'>
📝 We convert all networks with no exceptions; Glo, MTN, 9mobile, and Airtel.
</div>

<div className='mb-2'>
📝 We do not accept recharge card pins for airtime to cash conversion. Please load your recharge card pins and transfer the loaded airtime.
</div>

<div className='mb-2'>
📝 You must also not send us airtime from a bank account. Sending us VTU airtime from a bank account or any online wallet is forbidden and you will not be paid until is it cleared by the bank.
</div>

<div className='mb-2'>
📝 Please note that there is an N50 charge when you request us to pay into your bank account. Funding your wallet with airtime does not attract the N50 fee.
</div>

<div className='mb-2'>
📝 Airtime must only be sent from the phone number you submitted.
</div>

<div className='mb-2'>
📝 You will lose the payment for your airtime if you send below or above the amount you submitted. Please take note.
</div>

<div className='mb-2'>
📝 Do well to take a screenshot of the airtime you are sending if you can. It will help us to easily identify your request.
</div>

<div className='mb-2'>
📝 We have zero tolerance for stolen or fraudulent airtime to cash conversions. We may demand proof of ownership of the airtime you come to convert.
</div>

<div className='mb-2'>
📝 The minimum airtime amount you can send to us for conversion is 1,000. Please take note!
</div>

<div className='mb-2'>
📝 Kindly exercise patience and wait for us to process your payment without unnecessary calls and undue pressure. If you can't be patient, please do not send us airtime for conversion
</div>


    
      </span>
    

      <Link to={'/airtime-cash'} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn }>
        Proceed
      </Link>
    
                </div>
            </div>
            {/* <div className="mb-5"></div> */}
        </div>
    
    </div>
    
    
    </div>
    
        );
    }


export default AirtimeToCashReadMe