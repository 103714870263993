import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import TopBar from '../components/service/TopBar';
import TopCard from '../components/service/TopCard';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SetPin from '../components/modals/SetPin';
import { PulseLoader } from 'react-spinners';
import { Modal, Button } from 'react-bootstrap';

function Betting() {
    const navigate = useNavigate();
    
      const fetchPlans = async () => {
        dispatch({type: 'start'});
          await axios.post(baseURL+"/betting",  {
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
          }, 
      }).then((response) => {
          if (response.data.status == "failed" && response.data.message !== "") {
              return toaster("error", response.data.message);
            } else if (response.data.status == "success") {
              // console.log(response.data);
                PINHandler(!response.data.pin);
                  setValue({
                    ...value,
                    plans: response.data.betting,
                    });
                    setWallet(response.data.balance);
                    dispatch({type: 'stop'});
              } else {
                return toaster("error", error.loading.excemption);    
              }
    
          }).catch((e) => {
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
              return toaster("error", error.loading.excemption);
              // return e;
          })
      }
    
    
    /**************************************************************************/
    const [pinModal, setPinModal] = useState(false);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [isValidate, setIsValidate] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [modalShow, setModalShow] = useState(false);
    
    const [wallet, setWallet] = useState(''); 
    const [value, setValue] = useState({
          customerID: '',
          img: '/dashboard/images/betting.avif',
          imgWidth: '37px',
          imgHeight: '35px',
          plans: [],
          plan_id: '',
          selectedPlan: [],
          amount: '',
          biller: '',
          charge: '',
          charge_ii: '',

    });

   
      function planHandler(e) {
        // console.log('test');
        var plan_id = e.target.value;
        if (plan_id !== "") {
          var plan = value.plans.filter((e) => {
              return e.id == plan_id
          });
          console.log(plan);
          var charge = charge;
          setValue({...value, plan_id: plan_id, biller: plan[0].biller, selectedPlan: plan, charge: plan[0].charge, charge_ii: plan[0].charge_ii});
        } else {
          setValue({...value, plan_id: '', selectedPlan: []});
        }
      }
    
      /****************************************************************/

    function proceed(e) {
      e.preventDefault();
      if (value.selectedPlan.length !== 1) return toaster("error", error.validation.emptyPlan);      
      if (value.customerID == "") return toaster("error", error.validation.requiredInput);
      const amount = value.amount;
      if (wallet < amount && wallet !== amount) return toaster("error", error.lowBalance);
      // if (value.selectedPlan[0].amount < wallet || value.selectedPlan[0].amount !== wallet) return toaster("error", error.lowBalance)


        if (isValidate == false) { 
            validate();
            setIsValidate(true);
        return;
        }

      let object = {
          "service": "Betting",
          "id" : value.selectedPlan[0].id,
          "customerID": value.customerID,
          "amount": value.amount,
          "billerName": customerName,
          "biller": value.biller,
      };
      object = JSON.stringify(object);
      if(storage("save", "invoice", object, "sessionStorage")) {
          navigate("/invoice");
      } else {
          return alert(error.whoops);
      }
         
  }


  const hideModal = () => {
    setModalShow(false);
    setIsValidate(false);
  }
  
  const btnTxt = isValidate === true ? "Proceed" : "Validate";
  const [validateLoading, setValidateLoading] = useState(false);


  function notValidated() {
    setValidateLoading(false);
    setIsValidate(false);
  }
  
  
  function validate() {
    setValidateLoading(true);
    axios.post(baseURL+"/betting/validate", {
      id: value.selectedPlan[0].id,
      customerID: value.customerID,
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      },
    }).then((response) => {
        if (response.data.status == "success" && response.data.name !== "") {
          setCustomerName(response.data.name);   
          setValidateLoading(false);     
          setModalShow(true);
        } else if (response.data.status == "failed" && response.data.message !== "") {
          notValidated();
          return toaster("error", response.data.message);
        } else {
          notValidated();
          return toaster("error", error.whoops);  
        }
      }).catch((e)=>{
          notValidated();
          if (e.response !== undefined) {
            if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                storage("remove", "data", null, "localStorage");
                return navigate("/login");
            }
          }      
          return toaster("error", error.whoops);
          // return e;
      })
  
  }
     
  
  const PINHandler = (value) => {
    setPinModal(value);
  }  


  useEffect(() => {
    scrollToTop();
    fetchPlans();
    return () => axios.CancelToken.source();
  },[]);
    
    
    return (
        <div>

    
      <TopBar icon="fas fa-fire"/>
    
        <div className={"page-content " + Styles.page_content}>
    
    
        {/* Top Card */}
        <TopCard wallet={wallet}/>
    
        <div className="card card-clear" style={{ height: "170px" }}></div>
    


{/* Confirmation Modal */}

<Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className='text-center' style={{ marginBottom: "8px" }}> 
            <img src="/dashboard/images/betting.avif" 
              style={{ width: "70px", height: "70px", borderRadius: "50%" }} 
            /> 
        </div>
        <h4 className='text-center'> {customerName} </h4>      
        <p style={{ marginBottom: "1px" }}>
        <i> Please double-check that the above name corresponds to the account holder's name. </i>
        </p>
        <table className='table table-striped table-bordered' style={{ width:'100%', marginBottom: '0px' }}>
                <tbody>
                   
                    <tr>
                    <td style={{ fontSize: '12px' }}> <b>Customer ID</b> </td>
                        <td style={{ fontSize: '12px' }}> <span style={{ color: "blue" }}> <b>  {value.selectedPlan.length == 1 ? value.customerID : ''}  </b> </span> </td>

                        <td style={{ fontSize: '12px' }}> <b>Amount:</b> <span style={{ color: "green" }}><b> ₦{value.selectedPlan.length == 1 ? value.amount : ''} </b></span> </td>
                     </tr>

            </tbody>
        </table>
        <div style={{ marginTop: "20px" }}>
          <Button style={{ float: "left" }} onClick={hideModal} className='mr-auto btn-danger btn-sm'>Cancel</Button>
          <Button style={{ float: "right" }} onClick={proceed}  className='btn-success btn-sm btn-block'>Yes, it's correct</Button>
        </div>

      </Modal.Body>

    </Modal>


    
    
    

    
    <div className={"page-content pb-6 " + Styles.pt_10}>



    <ToastContainer/>

    
    <div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 } >
    
 
    <div className={"content mb-0 " + Styles.pt_13}>

    <SetPin visibility={pinModal} state={PINHandler} />

    <div hidden={!loading}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
      {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
          </div>
          <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
      </div>


      <div>
        <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme> 
        <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme>
        <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
      </div>

      </div>
    
    <span hidden={loading}>

    <h3><span> Betting </span>
    
    <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '10px', borderRadius: '50%', border: ""}}>
          <img src={ value.img } className="text-center" width={value.imgWidth} height={value.imgHeight} style={{  borderRadius: "50%"  }} />
          </span></h3>
    <p className={ Styles.description_txt }>
    
    Topup your bet account.
    
    </p>
    
    
    <div className="input-style has-borders no-icon mb-4">
        <select id="smile" onChange={planHandler} value={value.plan_id} className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Please select  </option>
        { value.plans.length !== 0 ? value.plans.map((e, index) => { return <option value={e.id} key={index}> {e.biller} </option> }) : ''}
        </select>
            <span><i className="fa fa-chevron-down"></i></span>
            <i className="fa fa-check disabled valid color-green-dark"></i>
            <i className="fa fa-check disabled invalid color-red-dark"></i>
            <em></em>
      </div>
    




    <div className="input-style has-borders has-icon validate-field mb-4" hidden={false}>
    <i className="fa fa-gamepad" style={{ color: "#008000" }}></i>
    <input type="number" autoComplete="off" value={value.customerID} onChange={(e) => setValue({...value, customerID: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Customer ID" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
  </div>


  <div className="input-style has-borders has-icon validate-field mb-4" hidden={false}>
    <i className="fa fa-money-bill-alt" style={{ color: "red" }}></i>
    <input type="number" autoComplete="off" value={value.amount} onChange={(e) => setValue({...value, amount: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Enter amount to topup" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
  </div>

  <div hidden={value.charge == "" && value.charge_ii == "" ? true: false} style={{ color: "red", fontSize: '11.5px', textAlign: 'center'}}>
      Charge: ₦{value.amount > 4999 ? value.charge_ii : value.charge}
  </div>



  <a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>

{ validateLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 { validateLoading ? '' : btnTxt }
  </a>
    
        </span>
    
                </div>
            </div>
            <div className="mb-5"></div>

        </div>
    
    </div>
    
    </div>
    
        );
    }


export default Betting