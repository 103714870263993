import { useEffect, useState, useReducer } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PulseLoader from "react-spinners/PulseLoader";
// import LoadingOverlay from 'react-loading-overlay';
import { ToastContainer } from 'react-toastify';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import TopBar from '../components/service/TopBar';
import TopCard from '../components/service/TopCard';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import SetPin from '../components/modals/SetPin';
import CopyToClipboard from 'react-copy-to-clipboard';
// import Beneficiary from '../components/Beneficiary';
// import Footer from '../components/Footer';
// import LoadingModal from '../components/modals/LoadingModal';
import {Modal, Button, ModalBody, ModalFooter, ModalHeader } from 'react-bootstrap';


function DataCard() {

  const navigate = useNavigate();
  // Fetch plans
  const fetchPlans = async () => {
      dispatch({type: 'start'});
      await axios.post(baseURL+"/data-card/plans",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
  }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
          return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
          PINHandler(!response.data.pin);
          setPlans(response.data.plans);
              setValue({
                ...value,
                networks: response.data.networks,
                });
                setWallet(response.data.balance);
                dispatch({type: 'stop'});
            } else {
              return toaster("error", error.loading.excemption);    
            }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          return toaster("error", error.loading.excemption);
          // return e;
      })
  }

  
  
/**************************************************************************/
// LoadingOverlay.propTypes = undefined;
const [loading, dispatch] = useReducer(loadingReducer, false);
  const [pinModal, setPinModal] = useState(false);
  const [network, setNetwork] =  useState('');
  const [wallet, setWallet] = useState('');
  const [plans, setPlans] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [dataPlans, setDataPlans] = useState([]);
  const [type, setType] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [value, setValue] = useState({
        quantity: '',
        selectedPlan: [],
        phoneNumber: '',
        validate: {
          number: true,
        },
        prefix: [],
        networks: [],
  });





/**************************************************************************/

const [image, setImage] = useState({
  src: '/dashboard/images/networks.jpg',
  borderColor: '#122614 #edc704 #17a00b #ed1d23',
  imageWidth: '37px',
  spanBorder: '1px solid',
  rotate: 'rotate',
})


const networkImage = (network) => {
        // console.log(network);
    switch (network) {
      // MTN
    case "MTN": setImage({
        src: "/dashboard/images/mtn.jpg",
        borderColor: '#f9c905',
        imageWidth: '35px',
        spanBorder: '2px solid',
        rotate: '',
    });
    
    break;
    // GLO
    case "GLO": setImage({
      src: "/dashboard/images/glo.jpg",
      borderColor: '#48b83e',
      imageWidth: '36px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

    // AIRTEL
    case "AIRTEL": setImage({
      src: "/dashboard/images/airtel.jpg",
      borderColor: '#ed1b24',
      imageWidth: '35px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

    // 9MOBILE
    case "9MOBILE": setImage({
      src: "/dashboard/images/9mobile.jpg",
      borderColor: '#006d52',
      imageWidth: '36px',
      spanBorder: '2px solid',
      rotate: '',
    });
    break;

      default: setImage({
        src: '/dashboard/images/networks.jpg',
        borderColor: '#122614 #edc704 #17a00b #ed1d23',
        imageWidth: '37px',
        spanBorder: '1px solid',
        rotate: 'rotate',
      });

    }

}


/**************************************************************************/

const networkHandler = (e) => {
  var network = e.target.value;
  // console.log(e);
  setNetwork(network);
  networkImage(network);
  let types = [];
  if (network !== "") {
  for (let i = 0; i < plans.length; i++) {
      if (plans[i].network === network) {
        types.push(plans[i].type);
      }
    }

      setPlanTypes([]); 
      setDataPlans([]);
      setType('');
      setValue({...value, selectedPlan: []});

    document.getElementById("type").selectedIndex = 0;
    document.getElementById("plan").selectedIndex = 0;
    const unique = Array.from(new Set(types));
    if (unique.length == 0) {
      toaster("error", error.service.notAvailable);
 
    } else if (unique.length == 1)  {      
      var plan = plans.filter((e) => {
        return e.network == network && e.type == unique[0];
      });
      setDataPlans(plan);
    } else {
      setPlanTypes(unique);
    } 
          
  } else {
    setPlanTypes([]);
    setDataPlans([]);
    setType('');
    setValue({...value, selectedPlan: []});
  }
}


/**************************************************************************/

function typeHandler(e) {
  var type_ = e.target.value;
  document.getElementById("plan").selectedIndex = 0;
  setValue({...value, selectedPlan: []});
  if (type_ !== "") {
    var plan = plans.filter((e) => {
        return e.network == network && e.type == type_;
    })
    setDataPlans(plan);
    setType(type_);      
  } else {
    setDataPlans([]);
    setType('');
  }
}

/**************************************************************************/

function planHandler(e) {
  var plan_id = e.target.value;
  var plan = dataPlans.filter((e) => {
    return e.id == plan_id;
  });
  setValue({...value, selectedPlan: plan})
}


/**************************************************************************/


function proceed(e) {
  e.preventDefault();
  if (network == "") return toaster("error", error.validation.emptyNetwork);
  if (value.selectedPlan.length == null || value.selectedPlan.length !== 1) return toaster("error", error.validation.emptyPlan);
  if (wallet < value.amount && wallet !== value.amount) return toaster("error", error.lowBalance);
  if (value.quantity == "") return toaster("error", error.validation.emptyQuantity); 
    let object = {
        "service": "Data Coupon",
        "id" : value.selectedPlan[0].id,
        "network" : value.selectedPlan[0].network,
        "quantity": value.quantity,
        "plan": value.selectedPlan[0].plan,
        "amount": value.amount,
        "name": value.name,        
    };
  object = JSON.stringify(object);
  if(storage("save", "invoice", object, 'sessionStorage')) {
      navigate("/invoice");
  } else {
      return alert(error.whoops);
  }
}


const PINHandler = (value) => {
  setPinModal(value);
}


function quantityHandler(e) {
    if (value.selectedPlan[0].amount !== '') {
        let quantity = e.target.value;  
        if (quantity < 61) {
          let price = value.selectedPlan[0].amount;
          let amount = price * quantity;
          quantity !== '' ? setValue({...value, quantity: quantity, amount: amount}) : setValue({...value, quantity: '', amount: ''});
        }
    } 
  }



useEffect(() => {
  scrollToTop();
  fetchPlans();
  // 
  window.addEventListener("resize", () => {
    const ismobile = window.innerWidth < 1200;
    if (ismobile !== isMobile) setIsMobile(ismobile);
  }, false);
  // 
  return () => axios.CancelToken.source();
}, [isMobile]);



return (
    <div>

{/* <LoadingOverlay active={loading} spinner={<ScaleLoader color={"white"} loading={true} height={40} />}  > */}
    {/* Top Bar  */}
<TopBar icon="fas fa-wifi"/>

<div className={"page-content " + Styles.page_content}>

{/* Top Card */}
<TopCard wallet={wallet}/>

<div className="card card-clear" style={{ height: "170px" }}></div>

<div className={"page-content pb-6 "}>

<ToastContainer />

<div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>

<div className={"content mb-0 " + Styles.pt_13}>

{/* <div className='col-lg-3 float-start'> <div className='card card-full shadow'> O3Developer </div> </div> */}

{/* <div className='col-lg-6 float-start'> <img src='' width={"90%"}/> </div> */}

<div className='col-lg-12'>

<SetPin visibility={pinModal} state={PINHandler} />


<div hidden={!loading}>

     <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
      {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
          </div>
          <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
      </div>



{loading ? <SkeletonTheme height={25} width={100}> <Skeleton count={1}/> </SkeletonTheme> : null }


<div>
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme> 
  <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }}   count={1}/> </SkeletonTheme> 
</div>




{loading ? <SkeletonTheme height={45} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> : null}

</div>



{/* <LoadingModal text="" key="load" visibility={loading}/> */}


<span hidden={loading}>


{/* Large screen display */}

<div className='col-lg-5 float-start container d-none d-lg-block'>


<div hidden={loading} className="alert rounded-s bg-blue-dark mb-0 mt-1 shadow-xl" role="alert" style={{ background: "linear-gradient(45deg, #ff3d00, red)" }}>
<strong className="alert-icon-text color-white" style={{ paddingLeft: "0px" }}>

        <div className='mb-2'>
          <img src="/dashboard/images/mtn.png" className="rounded-xl" width="25"/>
          <img src="/dashboard/images/glo.png" className="rounded-xl ms-n3" width="25"/>
          <img src="/dashboard/images/airtel.png" className="rounded-xl ms-n3" width="25"/>
          <img src="/dashboard/images/9mobile.png" className="rounded-xl ms-n3" style={{ background: "#ffffff" }} width="25"/> Data Balance Code
          </div>
          
  <div className="divider mt-0 mb-2" style={{ background: "#fff" }}></div>
  MTN SME - *461*4# | MTN Gifting - *131*4#
  <div className="divider mt-0 mb-2" style={{ background: "#fff" }}></div>
  GLO - *127*0# | Airtel - *140# | 9mobile - *228#
  </strong>
</div>


<div className='row mt-2 mb-0'>
  <div className='col-lg-6'>
  <Link to="/transaction" className={"card card-style py-4 mx-0 mb-3 " + Styles.card_border}>
        <div className="d-flex px-3">
        <div className="align-self-center">
          <img src="/dashboard/images/transaction-history.png" height="60" width="60" className="rounded-xl" />
        </div>
        <div className="ps-2 align-self-center" style={{ lineHeight: "20px" }}>
        <h4 className="mb-1"> History </h4>
        <span className="font-12 color-black opacity-70" > View all transaction history </span>
        </div>
        <div className="ms-auto align-self-center">
        <i className="fa fa-arrow-right opacity-30 color-theme"></i>
        </div>
        </div>
      </Link>
    </div>

  
  <div className='col-lg-6'>
  <Link to="/fund-wallet" className={"card card-style py-4 mx-0 mb-3 " + Styles.card_border}>
        <div className="d-flex px-3">
        <div className="align-self-center">
        <img src="/dashboard/images/credit-card_.png" height="60" width="60" className="rounded-xl" />
        </div>
        <div className="ps-2 align-self-center" style={{ lineHeight: "20px" }}>
        <h4 className="mb-1"> Fund Wallet </h4>
        <span className="font-12 color-black opacity-70" > Topup your wallet balance </span>
        </div>
        <div className="ms-auto align-self-center">
        <i className="fa fa-arrow-right opacity-30 color-theme"></i>
        </div>
        </div>
      </Link>
    </div>


</div>






<div className={"card card-style m-0 " + Styles.card_border}>
  <div className="content mb-0">
  <h4 className="font-700">Other Services</h4>
  <p className='mb-1'>
  You can also make purchase for any of the service below.
  </p>
    <Link to="/airtime" className="chip chip-s" style={{ background: 'purple' }}>
      <img src="/dashboard/images/airtime.svg" className='bg-white' alt="img"/>
      <span className="color-white">Buy Airtime</span>
    </Link>

    <Link to="/cable" className="chip chip-s bg-red-dark" style={{ background: '#009688' }}>
      <img src="/dashboard/images/Multichoice-banner.jpg" className='bg-white' alt="img"/>
      <span className="color-white">Cable Subscription</span>
    </Link>


    <Link to="/electricity" className="chip chip-s" style={{ background: '#ff5722' }}>
    <img src="/dashboard/images/electricity-power-line.jpg" className='bg-white' alt="img"/>
    <span className="color-white">Electricity</span>
    </Link>

  </div>
</div>



</div>

{/* Large screen dsiplay ends here */}


<div className='col-lg-7 col-md-12 col-xs-12 col-sm-12 float-end data_card_transform'>
  
<div className={!isMobile ? "container" : ""}>
<div className="lg_card_raise">

<h3>
   <span> Data Coupon</span> 

 <span  className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%', border: image.spanBorder, borderColor: image.borderColor}}>             
      <img src={ image.src } className={"text-center " + image.rotate} width={image.imageWidth} style={{  borderRadius: "50%"  }} /> 
      </span> 
      
      </h3> 

      <p className={ Styles.description_txt }>
        Enjoy our incredibly low-cost data 📶 package for your internet browsing now!!!🔥.
      </p>

  
</div>



<div className="col-lg-12 input-style has-borders no-icon mb-4">      
        <select id="network" onChange={networkHandler} value={network} className="shadow-s">
        <option value=""> Please select  </option>
        { value.networks && value.networks.length !== null && value.networks.length !== 0 ? value.networks.map((e, index) => { return <option value={e.name} key={index}> {e.name} </option> }) : ''}
        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>


  
<div className="input-style has-borders no-icon mb-4" hidden={ planTypes.length !== null && planTypes.length > 0 ? false : true  }>
        {/* <label htmlFor="type" className="color-highlight">Type</label> */}
        <select id="type" className="shadow-s" onChange={typeHandler}>
        <option value=""> Please select  </option>
        { planTypes.length !== null && planTypes.length !== 0 ? planTypes.map((e, index) => { return <option value={e} key={index}> {e} </option> }) : ''}

        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
    </div>



  <div className="input-style has-borders no-icon mb-4" hidden={ dataPlans.length !== null && dataPlans.length > 0 ? false : true }>
        {/* <label htmlFor="form5" className="color-highlight">Plan</label> */}
        <select id="plan" onChange={ planHandler } className="shadow-s">
        <option value=""> Select plan</option>
        { dataPlans.length !== 0 ? dataPlans.map((e, index) => { return <option value={e.id} key={index}> { e.plan }{ " -" } &#8358;{ e.amount } </option> }) : ''}

        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>





  <div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.selectedPlan.length > 0 ? false : true }>
    <i className="fas fa-print" style={{ color: "#ff0000" }}></i>
    <input type="number" autoComplete="off" value={value.quantity} onChange={ quantityHandler } className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Type qunatity [max 60]" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
    </div>
    
 
  <div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.quantity !== '' ? false : true }>
    {/* <i className="fa fa-money" style={{ color: "#000094" }}></i> */}
    <i className="fas fa-money-bill-alt"></i>
    <input type="text" readOnly autoComplete="off" value={value.amount} onChange={ (e) => {setValue({...value, amount: e.target.value})} } className="form-control validate-name shadow-bg-m shadow-s" id="amount" placeholder="Enter amount" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    {/* <em>(required)</em> */}
    </div>





      <div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.quantity !== '' ? false : true }>
    <i className="fas fa-arrow-right" style={{ color: "#008000" }}></i>
    <input type="text" autoComplete="off" maxLength={20}  value={value.name} onChange={(e) => setValue({...value, name: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Name on card [E.g your name or bussiness name]" />
    {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
    <i className="fa fa-times disabled invalid color-red-dark"></i>
    <i className="fa fa-check disabled valid color-green-dark"></i>
    <em>(required)</em>
    </div>







<a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>
    Proceed
  </a>

  </div>
  
</div>
  </span>


  </div>

            </div>






        </div>


        <div className="mb-6"></div>



    </div>




    {/* <Footer/> */}
</div>

{/* </LoadingOverlay> */}

</div>

    );
}


export default DataCard;